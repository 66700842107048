import Vibrant from 'node-vibrant'

export const handleColorImage = async(image: string): Promise<any> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    const vibrant = new Vibrant(image, { maxDimension: 100 })
    const palette = await vibrant.getPalette()

    if (palette) {
      const res = Object.keys(palette).reduce((acc, current) =>
        (palette[acc]!.population > palette[current]!.population) ? acc : current)
      resolve(palette[res]!.population > 1000 ? palette[res]!.hex : '#fff')
    }

    resolve('#fff')
  })
}
